<template>
  <div class="app-container">
    <eHeader :query="query" :sup_this="sup_this" />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      border
      style="width: 100%"
    >
      <el-table-column prop="name" label="职位名称"  />
      <el-table-column prop="description" label="职位描述" />
      <el-table-column label="操作" width="200px" align="center">
        <template slot-scope="scope">
          <edit
            :data="scope.row"
          
            :sup_this="sup_this"
          />
          <el-popover
            :ref="scope.row.id"
            placement="top"
            width="180"
          >
            <p>确定删除本条数据吗？所有关联的数据将会被清除</p>
            <div style="text-align: right; margin: 0">
              <el-button
                size="mini"
                type="text"
                @click="$refs[scope.row.id].doClose()"
                >取消</el-button
              >
              <el-button
                :loading="delLoading"
                type="primary"
                size="mini"
                @click="subDelete(scope.row.id)"
                >确定</el-button
              >
            </div>
            <el-button
              slot="reference"
              type="danger"
              size="mini"
              >删除</el-button
            >
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"
    />
  </div>
</template>

<script>

import initData from "@/mixins/initData";
import { del } from "@/api/positionApi";
import { parseTime } from "@/utils/index";
import eHeader from "./module/header";
import edit from "./module/edit";
export default {
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
    };
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    
    beforeInit() {
      this.url = "api/position/";
      const sort = "id";
      const query = this.query;
      const value = query.value;
      this.params = { page: this.page, size: this.size, ordering: sort };
      if (value) {
        this.params["search"] = value;
      }
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$message({
            showClose: true,
            type: "success",
            message: "删除成功!",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err);
        });
    },
   
  },
};
</script>

<style scoped>
</style>
